
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { TYPES } from '@/core/config/Types';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { FindByDocumentTypeContableConcept } from '@/courier/application/uses_cases/ContableConcepts/search/FindByDocumentTypeContableConcept';
import { GetDocsLinByTimeline } from '@/courier/application/uses_cases/DocsLin/search/GetDocsLinByTimeline';
import { SaveDocumentsV2 } from '@/courier/application/uses_cases/DocumentsV2/create/CreateDocumentsV2';
import { FindDocumentsByPkV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByPkV2';
import { FindHouseByEntityAndMaster } from '@/courier/application/uses_cases/House/search/FindHouseByEntityAndMaster';
import { ContableConcepts } from '@/courier/domain/ContableConcepts/ContableConcepts';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { House } from '@/courier/domain/House/House';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { FindAllTypeDocument } from '@/settings/application/uses_cases/TypeDocument/search/FindAllTypeDocument';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import { Company } from '@/settings/domain/company/Company';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { FindPackagingByReferenceAndType } from '@/wms/application/catalogs/packaging/search/FindPackagingByReferenceAndType';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { FindPackagingByType } from '@/wms/application/packingUnit/search/FindPackagingByType';
import { CalculateAverageCost } from '@/wms/application/stock/search/CalculateAverageCost';
import { FindReferenceStock } from '@/wms/application/stock/search/FindReferenceStock';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { References } from '@/wms/domain/catalogs/references/References';
import { Store } from '@/wms/domain/layout/store/Store';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Inject } from 'inversify-props';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'DocForm',
  components: { CustomTableN }
})
export default class DocForm extends Vue {
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.FINDBYSTATUS_WAREHOUSE)
  readonly findWarehouseByState!: WarehouseFindByStatus;
  @Inject(TYPES.FIND_ALL_STORE_WAREHOUSE)
  readonly findStoreWareHouse!: FindAllStorebyWarehouse;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly referenceFindAll!: ReferenceFindAll;
  @Inject(TYPES.FINDALL_BY_TYPE_PACKINGUNIT_REFERENCE)
  readonly findPackingByType!: FindPackagingByType;
  @Inject(TYPES.FIND_STOCK_REFERENCE)
  readonly findReferenceStock!: FindReferenceStock;
  @Inject(TYPES.CALCULATE_AVERAGE_COST)
  readonly calculateAverageCost!: CalculateAverageCost;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;
  @Inject(TYPES.DOCUMENTS_V2_SAVE)
  readonly saveDocumentV2!: SaveDocumentsV2;
  @Inject(TYPES.TYPE_DOCUMENT_FIND_ALL)
  readonly findAllTypeDocuments!: FindAllTypeDocument;
  @Inject(TYPES.CONTABLE_CONCEPTS_FIND_ALL_BY_DOCUMENT_TYPE)
  readonly findContableConceptsByDocType!: FindByDocumentTypeContableConcept;
  @Inject(TYPES.DOCUMENTS_V2_FIND_BY_PK)
  readonly findDocumentByPkV2!: FindDocumentsByPkV2;
  @Inject(TYPES.HOUSE_FIND_ALL_BY_ENTITY_AND_MASTER)
  readonly findHouseByEntityAndMaster!: FindHouseByEntityAndMaster;
  @Inject(TYPES.DOCS_LIN_FIND_BY_TIMELINE)
  readonly findDocsLinByTimeline!: GetDocsLinByTimeline;
  @Inject(TYPES.FIND_PACKAGING_BY_REFERENCE_AND_TYPE)
  readonly findPackagingByReferenceAndType!: FindPackagingByReferenceAndType;
  @Inject(TYPES.FINDALL_TERMS_PAYMENT)
  readonly findAllTermsPayment!: TermsPaymentFindAll;

  /** Props */
  @Prop({ type: Array, required: true }) filterSwitchs!: Array<string>;
  @Prop({ type: Number, required: true }) masterNumber!: number;
  @Prop({ required: true }) readonly master!: PreRegister;
  // @Prop({ type: Array, required: true }) readonly houses!: any[];
  @Prop({ type: String, required: false }) readonly typeOperation!: string;
  @Prop({ required: true }) activityData!: ActivityTransactional;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;

  /** Refs */
  $refs!: {
    operational_form: InstanceType<typeof ValidationObserver>;
    lines_form: InstanceType<typeof ValidationObserver>;
  };

  //#region States
  isLoading = false;
  tabIndex = 2;
  companyData: Company | null = null;
  form: DocumentHeader = new DocumentHeader();
  formLines: OrderLine = new OrderLine();
  entities: Entity[] = [];
  warehouses: Warehouse[] = [];
  stores: Store[] = [];
  listReferences: References[] = [];
  paymentTerms: TermsPayment[] = [];
  timelineReferences: References[] = [];
  listPackaging: Packaging[] = [];
  typesDocuments: TypeDocument[] = [];
  contableConcepts: ContableConcepts[] = [];
  houses: House[] = [];
  switchsA = ['3', '4', '11', '13', '14', '16'];
  switchsB = ['1', '2', '12'];
  houseSelected = null;
  typesInvoicing = ['COMERCIAL', 'GIFT'];
  // conditions: TermsPayment[] = [
  //   { id: 1, name: `${this.$i18n.t('general.cash')}`, description: null },
  //   { id: 2, name: `${this.$i18n.t('general.credit')}`, description: null }
  // ];
  //#endregion

  //#region Methods
  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.editLineItem
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.removeItemFromLines
      }
    ]
  };
  //#endregion

  /** Getters */
  get numberIndexLine() {
    return this.form.lines.length;
  }

  get calculateToMinUnit() {
    if (this.formLines.quantity > 0 && this.formLines.packaging && this.formLines.packaging?.numberUnits > 0) {
      return Number(this.formLines.quantity) * Number(this.formLines.packaging?.numberUnits);
    }

    return 0;
  }

  get calculatedValue() {
    if (this.formLines.unitPrice == null || this.formLines.servicePrice == null) return '0';
    return (
      (!this.formLines.reference?.noManageStock ? this.formLines.unitPrice : this.formLines.servicePrice) *
      (1 - this.formLines.discountRate / 100) *
      (this.formLines.packaging?.numberUnits ?? 0)
    ).toFixed(2);
  }

  get calculatedTotalValue() {
    if (this.calculatedValue == null) return 0;
    return (parseFloat(this.calculatedValue) * (1 + this.formLines.totalTaxes / 100) * this.formLines.quantity).toFixed(
      2
    );
  }

  get subTotal() {
    let sum = 0;
    this.form.lines.forEach((line: any) => {
      sum += line.active ? line.partialPrice : 0;
    });

    return sum.toFixed(2);
  }

  get totalTaxesValue() {
    let sum = 0;
    this.form.lines.forEach((line: any) => {
      sum += line.active ? (line.partialPrice * line.totalTaxes) / 100 : 0;
    });

    return sum > 0 ? sum.toFixed(2) : 0;
  }

  get totalOrderValue() {
    let sum = 0;
    this.form.lines.forEach((line: any) => {
      sum += line.active ? line.purchaseValue : 0;
    });

    return sum.toFixed(2);
  }

  get dateNow() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const today = String(new Date().getDate()).padStart(2, '0');
    return `${year}-${month < 10 ? '0' : ''}${month}-${today}`;
  }

  get eventExecuted() {
    // TODO: Implementar logica para saber si el evento ya fue ejecutado
    return false;
  }

  get fields() {
    return [
      {
        field: 'reference.code',
        label: this.$i18n.t('general.SKU'),
        sortable: true,
        tdClass: (row: any) => !row.active && 'bg-danger text-white'
      },
      {
        field: 'reference.filterBrand',
        label: this.$i18n.t('general.description'),
        sortable: true
      },
      {
        field: 'quantity',
        label: this.$i18n.t('general.quantity'),
        sortable: true
      },
      {
        field: 'packaging.numberUnits',
        label: this.$i18n.t('general.packaging'),
        sortable: true
      },
      {
        field: 'packaging.numberUnits',
        label: this.$i18n.t('general.unitsquantity'),
        sortable: true
      },
      {
        field: (row: OrderLine) => {
          return !row.reference?.noManageStock ? row.unitPrice : row.servicePrice;
        },
        label: this.$i18n.t('general.originalPrice'),
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0)
      },
      {
        field: 'discountRate',
        label: this.$i18n.t('general.aditionalDiscount'),
        sortable: true
      },
      {
        field: 'totalTaxes',
        label: `${this.$i18n.t('general.taxes')} (%)`,
        sortable: true
      },
      {
        field: (row: OrderLine) => {
          return Number((row.purchaseValue / row.unitsQuantity).toFixed(4));
        },
        label: this.$i18n.t('general.unitprice'),
        sortable: true
      },
      {
        field: 'purchaseValue',
        label: this.$i18n.t('general.price'),
        sortable: true
      },
      {
        field: 'actions',
        label: this.$i18n.t('general.actions'),
        hidden: this.eventExecuted
      }
    ];
  }

  /** Methods & Functions */
  mounted() {
    this.findCompanyById();
    this.searchWarehouses();
    this.findReferences();
    this.getTypeDocuments();
    this.findTimelineReferences();
    this.findPaymentTerms();
    this.form.date = this.dateNow;

    if (this.activityData?.documentHeader) {
      const loadForm: DocumentHeader = {
        ...this.activityData?.documentHeader,
        date: getFormattedDate(new Date(this.activityData?.documentHeader?.date)),
        dueDate: this.activityData?.documentHeader?.dueDate
          ? getFormattedDate(new Date(this.activityData?.documentHeader?.dueDate))
          : ''
      };

      this.form = loadForm;
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  customWarehouseLabel(warehouse: Warehouse) {
    return `${warehouse.code} - ${warehouse.description}`;
  }

  customStoreLabel(store: Store) {
    return `${store.storeId} - ${store.storeName}`;
  }

  customReferenceLabel(reference: References) {
    return `${reference.code} - ${reference.filterBrand}`;
  }

  customLabelPackaging(packaging: Packaging) {
    return `${packaging.unit?.description}`;
  }

  customTypeDocumentLabel(documentType: TypeDocument) {
    return `${documentType.type} - ${documentType.description}`;
  }

  async getConsecutive(input: TypeDocument | null) {
    if (!input) return;

    const payload = {
      type: input.type,
      warehouse: '000'
    } as const;

    try {
      this.isLoading = true;
      const res: any = await this.consecutiveFindByWarehouseAndType.execute(payload);
      this.form.number = Number(res.nextNumber) + 1;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getContableConcepts(input: TypeDocument | null) {
    if (!input) return;

    try {
      this.isLoading = true;
      const res = await this.findContableConceptsByDocType.execute(input.type);
      this.contableConcepts = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async handleDocumentType(input: TypeDocument | null) {
    await this.getConsecutive(input);
    await this.getContableConcepts(input);
  }

  async getHousesByEntityAndMaster(entity: Entity) {
    try {
      this.isLoading = true;

      const payload = {
        entityCode: entity.code,
        trackingNumber: this.master.trackingNumber?.trackingNumber as string
      };
      const res = await this.findHouseByEntityAndMaster.execute(payload);
      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      this.houses = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getTypeDocuments() {
    try {
      this.isLoading = true;
      const res = await this.findAllTypeDocuments.execute();
      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      this.typesDocuments = res.filter((docType: TypeDocument) => this.filterSwitchs.includes(docType.sw));
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findCompanyById() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.companyFindById.internalExecute(companyId);
      this.companyData = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async asyncFindEntity(query: string) {
    if (query.length >= 3) {
      try {
        const params: any = {
          text: query,
          type: ['SUPPLIER', 'CUSTOMER']
        };

        this.isLoading = true;
        const res = await this.customerFindFilter.execute(params);
        if ('error' in res || 'message' in res) {
          this.isLoading = false;
          return;
        }

        this.entities = res;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        throw new Error(`${error}`);
      }
    }
  }

  async verifyCustomer(input: Entity) {
    if (input.contacts.length < 1) {
      this.$swal({
        title: this.$t('general.warning'),
        text: `${this.$t('general.undefinedPhoneOrEmail')}`,
        icon: 'warning'
      });
      this.form.customer = null;
      return false;
    }

    const date = new Date();
    date.setDate(date.getDate() + Number(input.delivery));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const today = String(date.getDate()).padStart(2, '0');
    this.form.dueDate = String(`${year}-${month < 10 ? '0' : ''}${month}-${today}`);

    this.form.condition = input.termsPayment;

    await this.getHousesByEntityAndMaster(input);
  }

  async searchWarehouses() {
    try {
      this.isLoading = true;
      const res = await this.findWarehouseByState.execute(true);
      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      this.warehouses = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async searchStore(input: Warehouse | null) {
    if (!input) return;

    try {
      this.isLoading = true;
      const res = await this.findStoreWareHouse.execute(input.code);
      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      this.stores = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  setForBondedEntry(input: Store) {
    this.form.forBondedEntry = input.forBondedEntry;
  }

  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.referenceFindAll.execute();
      this.listReferences = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  editLineItem(item: OrderLine, index: number) {
    this.form.lines.splice(index, 1);
    this.formLines = item;
  }

  async removeItemFromLines(item: OrderLine) {
    const message = await this.$swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      title: this.$t('general.info'),
      text: `${this.$t('general.areyousuretodeletethis')} ${this.$t('general.reference')} (${
        item.reference?.filterBrand
      })`,
      confirmButtonText: `${this.$t('general.delete')}`,
      cancelButtonText: `${this.$t('general.cancel')}`,
      icon: 'warning'
    });

    if (!message.isConfirmed) return;
    const itemPos = this.form.lines.indexOf(item);

    if (item.number) {
      item.active = false;
      return;
    }

    this.form.lines.splice(itemPos, 1);
    if (this.form.documentType?.sw == '3') this.calculatePriceDistribution();
  }

  async getReferenceAverageCost(reference: References) {
    try {
      this.isLoading = true;
      const res = await this.calculateAverageCost.execute({
        reference: encodeURIComponent(reference.code)
      });

      this.formLines.unitCost = Number(res.costoPromedio.toFixed(2));
      this.formLines.unitValue = !reference.noManageStock
        ? Number(this.formLines.unitPrice)
        : Number(this.formLines.servicePrice);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findPackaginByReference(refrence: References) {
    const value = !['3', '4'].includes(this.form.documentType?.sw as string)
      ? this.formLines.reference?.msrp
      : this.formLines.reference?.standardCost;

    if (refrence.applyDiscount) {
      this.formLines.discountRate = Number(this.form.customer?.discountPercentage) || 0;
    }

    if (refrence.noManageStock) {
      this.formLines.servicePrice = value || 0;
    } else {
      this.formLines.unitPrice = value || 0;
    }

    if (this.switchsB.includes(this.form.documentType?.sw as string)) this.getReferenceAverageCost(refrence);

    const taxes = refrence.saleTax;
    this.formLines.totalTaxes = taxes > 0 ? taxes : 0;
    try {
      this.isLoading = true;
      const res = await this.findPackagingByReferenceAndType.execute({
        reference: refrence.code,
        type: 'sale'
      });
      this.listPackaging = res;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  calculateLinePrice() {
    let value = !['3', '4'].includes(this.form.documentType?.sw as string)
      ? this.formLines.reference?.msrp
      : this.formLines.reference?.standardCost;

    if (this.form.documentType?.sw === '3' && this.formLines.reference?.noManageStock) {
      this.formLines.servicePrice = Number(value);
    }
    if (value == null) value = 0;
    this.formLines.unitPrice = value;
  }

  calculatePriceDistribution() {
    let totalServices = 0;
    let totalLines = 0;
    this.form.lines.forEach((line: OrderLine) => {
      if (line.reference?.noManageStock) {
        totalServices += Number(line.servicePrice);
      } else {
        totalLines += Number(line.originalPrice);
      }
    });

    const percentage = totalLines > 0 ? totalServices / totalLines : 0;
    this.form.lines.forEach((line: OrderLine) => {
      if (!line.reference?.noManageStock) {
        line.purchaseValue = Number(
          Number(line.originalPrice * ((line.reference?.isPromotional ? 0 : 1) + percentage)).toFixed(2)
        );
      }
    });
  }

  clearformLine() {
    this.formLines = new OrderLine();
    this.$nextTick(() => {
      this.$refs.lines_form.reset();
    });
  }

  addNewItemtoForm() {
    this.formLines.originalPrice = parseFloat(this.calculatedTotalValue.toString());
    this.formLines.purchaseValue = parseFloat(this.calculatedTotalValue.toString());
    this.formLines.partialPrice = Number((parseFloat(this.calculatedValue) * this.formLines.quantity).toFixed(2));
    this.formLines.unitsQuantity = this.calculateToMinUnit;
    this.formLines.handleInventory = this.formLines.reference?.noManageStock ? 'N' : 'S';

    if (this.switchsA.includes(this.form.documentType?.sw as string)) {
      this.formLines.unitCost = Number(this.calculatedValue);
      this.formLines.unitValue = this.formLines.unitCost;
    } else if (this.switchsB.includes(this.form.documentType?.sw as string)) {
      this.formLines.unitValue = Number(this.calculatedValue);
    }

    this.form.lines.push(this.formLines);

    //Calculo de la distribucion de precio
    if (this.form.documentType?.sw == '3') this.calculatePriceDistribution();

    this.clearformLine();
  }

  async saveDocument(payload: DocumentHeader) {
    try {
      this.isLoading = true;

      const res = await this.saveDocumentV2.execute(payload);
      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        throw new Error();
      }

      this.clearForm();
      this.isLoading = false;
      return res;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async saveForm() {
    if (!(await this.$refs.operational_form.validate())) return;

    if (this.form.lines.length < 1) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.errorPackagesNumber')}`,
        icon: 'warning'
      });
      return;
    }

    if (!this.form.documentType || !this.form.contableConcept) return;

    const editedSwitch = Object.assign({}, this.form.documentType, {
      sw: String(parseInt(this.form.documentType.sw, 10) + 100)
    });

    const payload: DocumentHeader = Object.assign(
      {},
      {
        ...this.form,
        date: new Date(this.form.date),
        dueDate: new Date(this.form.dueDate),
        total: Number(this.totalOrderValue),
        documentType: editedSwitch,
        contableConcept: {
          ...this.form.contableConcept,
          documentType: {
            ...this.form.documentType,
            sw: editedSwitch.sw
          }
        },
        sw: {
          sw: editedSwitch.sw,
          description: '',
          nature: '',
          active: false,
          keyModal: '',
          natureEs: '',
          processType: 0,
          processTypeName: '',
          handleInventory: false
        }
      }
    );

    try {
      if (this.saveProcess) {
        const payloadActivity = Object.assign(this.activityData, {
          ...this.activityData,
          documentHeader: payload
        });
        await this.saveProcess(payloadActivity);
      } else {
        await this.saveDocument(payload);
      }
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findTimelineReferences() {
    try {
      if (!this.master || !this.master.timeline?.timelineOrigin) return;
      this.isLoading = true;
      const res = await this.findDocsLinByTimeline.internalExecute({
        timeline: this.master.timeline?.timelineOrigin?.id
      });

      res.forEach((item: OrderLine) => {
        this.formLines = Object.assign(new OrderLine(), item);
        this.addNewItemtoForm();
      });

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findPaymentTerms() {
    try {
      this.isLoading = true;
      const res = await this.findAllTermsPayment.execute();
      this.paymentTerms = res;
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  clearForm() {
    this.form = new DocumentHeader();
    this.formLines = new OrderLine();
    this.$nextTick(() => {
      this.$refs.operational_form.reset();
      this.$refs.lines_form.reset();
    });
  }
}
