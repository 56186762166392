import { TaxesLine } from '@/courier/domain/taxesline/TaxesLine';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { References } from '@/wms/domain/catalogs/references/References';

export class OrderLine {
  type: string;
  warehouse: string;
  number: number;
  sw: string;
  sequence: number;
  quantity: number;
  amountShipped: number;
  scheduled: number;
  unitPrice: number | null;
  discountRate = 0.0;
  units = 'UND';
  unitsQuantity: number;
  comment: string;
  fobValue: number;
  unitCost: number | null;
  unitValue: number | null;
  active = true;
  weight: number | null;
  height: number | null;
  width: number | null;
  lengthy: number | null;
  freight: number;
  purchaseUrl: string;
  description: string;
  enabled: boolean;
  taxes: TaxesLine[] = [];
  flagInit: boolean;
  refrence: References | null;
  reference: References | null;
  purchaseValue: number;
  packagin: Packaging | null;
  packaging: Packaging | null;
  declaredValue: number;
  commodity: CommodityType | null;
  selectedProvider: any;
  guideNumber: number;
  handleInventory: string;
  totalTaxes: number;
  partialPrice: number;
  servicePrice: number;
  originalPrice: number;
  batch: string;
  batchExpiration: string;

  public constructor() {
    this.type = '';
    this.warehouse = '';
    this.number = 0;
    this.sw = '';
    this.sequence = 0;
    this.quantity = 1;
    this.amountShipped = 0;
    this.scheduled = 0;
    this.unitPrice = null;
    this.discountRate = 0;
    this.units = '';
    this.unitsQuantity = 0;
    this.comment = '';
    this.fobValue = 0;
    this.unitCost = null;
    this.unitValue = null;
    this.active = true;
    this.weight = 1;
    this.height = 1;
    this.width = 1;
    this.lengthy = 1;
    this.freight = 0;
    this.purchaseUrl = '';
    this.description = '';
    this.enabled = false;
    this.flagInit = false;
    this.refrence = null;
    this.reference = null;
    this.purchaseValue = 0.0;
    this.packagin = null;
    this.packaging = null;
    this.declaredValue = 0;
    this.commodity = null;
    this.selectedProvider = null;
    this.guideNumber = 0;
    this.handleInventory = '';
    this.totalTaxes = 0;
    this.partialPrice = 0;
    this.servicePrice = 0;
    this.originalPrice = 0;
    this.batch = '';
    this.batchExpiration = '';
  }
}
